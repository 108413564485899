import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Disclosure from "../../../components/blog/legal/affiliateMarketing.de.mdx";
import BlogOverview from "../../../components/blog/overview/blogOverview.de.mdx";
import Layout from "../../../components/blog/layout.de";
export const query = graphql`
  {
    mdx(
      slug: { eq: "was-ist-der-unterschied-zwischen-whey-und-casein/index.de" }
    ) {
      frontmatter {
        main_image {
          publicURL
        }
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_first_desc
        image_first_credit_text
        image_first_credit_link
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_second_desc
        image_second_credit_text
        image_second_credit_link
        image_app_promotion {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_app_promotion_alt
        image_app_promotion_desc
        image_app_promotion_credit_text
        image_app_promotion_credit_link
        date(formatString: "D. MMMM YYYY")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const NavLinkGlobal = makeShortcode("NavLinkGlobal");
const Image = makeShortcode("Image");
const AffiliateLink = makeShortcode("AffiliateLink");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Du befasst dich mit den Themen
  Ernährung, Fitness und Supplements und bist kürzlich über die Begriffe{" "}
  <NavLinkGlobal to="/de/blog/was-bedeutet-whey-protein" mdxType="NavLinkGlobal">
    Whey Protein
  </NavLinkGlobal>{" "}
  und{" "}
  <NavLinkGlobal to="/de/blog/fuer-was-ist-casein-gut" mdxType="NavLinkGlobal">Casein</NavLinkGlobal>{" "}
  gestolpert? Du bist jetzt von der Menge an Informationen überfordert und
  willst einfach nur wissen, welches Produkt für dich und deine Ziele das
  richtige ist? In diesem Artikel erfährst du alles über den Unterschied
  zwischen Whey und Casein!
    </p>
    <h2>{`Die wichtigsten Eigenschaften im Überblick`}</h2>
    <p>
  Du möchtest so schnell wie möglich erfahren, welches Eiweiß das richtige für
  dich und deine Ziele ist und wann es eingenommen werden sollte. Du hast aber
  keine Lust seitenlange Texte durchzulesen, um dich über alle Fakten zu
  informieren? Kann ich nur zu gut verstehen, deshalb findest du hier alle
  wichtigen Informationen zu Whey und Casein als Zusammenfassung!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} imageDesc={props.data.mdx.frontmatter.image_first_desc} imageAuthor={props.data.mdx.frontmatter.image_first_credit_text} imageLink={props.data.mdx.frontmatter.image_first_credit_link} mdxType="Image" />
    <h3>{`Besondere Eigenschaften von Whey Protein`}</h3>
    <p>
  <NavLinkGlobal to="/de/blog/was-bedeutet-whey-protein" mdxType="NavLinkGlobal">
    Whey Protein
  </NavLinkGlobal>{" "}
  ist ein Molkeeiweiß, es wird also aus der Molke der Milch bei der
  Käseproduktion gewonnen. Es ist ein sehr hochwertiges Protein, mit einer hohen
  biologischen Wertigkeit. Das bedeutet, dass dein Körper aus Molkeeiweiß sehr
  gut körpereigenes Gewebe wie z.B. Muskulatur herstellen kann. Es enthält alle
  essentiellen Aminosäuren und wird deshalb als vollständiges Protein
  bezeichnet.
    </p>
    <p>
  Insbesondere ist{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_whey_text}
  </AffiliateLink>{" "}
  ein leicht verdauliches, schnell resorbierbares Eiweiß. Nachdem du einen Whey
  Protein Shake zu dir genommen hast, können bereits nach wenigen Minuten
  Aminosäuren in deinem Blut nachgewiesen werden. Das führt zu hohen
  Konzentrationen von Aminosäuren, die wiederum nach kurzer Zeit vollständig
  verwertet werden. Das heißt also, dass du schnell für einen relativ kurzen
  Zeitraum hohe Mengen an Aminosäuren in deinem Blut hast. Studien belegen
  eindeutig, das dies zu einer erhöhten Proteinsynthese führt. Du baust also,
  einen bestimmten Trainingsreiz vorausgesetzt, Muskulatur auf. Die Wirkung von
  Whey Protein ist also primär anabol, also muskelaufbauend.
    </p>
    <p>
  Bei der Suche nach passenden Produkten wirst du auf{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_whey_iso_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_whey_iso_text}
  </AffiliateLink>{" "}
  stoßen. Whey Isolat hat verglichen mit einem Whey Protein Konzentrat eine
  höhere Eiweißkonzentration. Das wird dadurch erreicht, dass die Kohlenhydrat-
  und Fettanteile nochmals deutlich reduziert werden konnten. Besonders bei
  Laktoseintoleranz, kohlenhydrat- und fettarmen Diäten ist ein Whey Protein
  Isolat einem gewöhnlichen Konzentrat überlegen.
    </p>
    <p>
  Möchtest du dich intensiv mit dem Thema Whey Protein befassen, so findest du{" "}
  <NavLinkGlobal to="/de/blog/was-bedeutet-whey-protein" mdxType="NavLinkGlobal">hier</NavLinkGlobal>{" "}
  einen Artikel, der keine Frage offen lässt!
    </p>
    <h3>{`Besondere Eigenschaften von Casein`}</h3>
    <p>
  Kuhmilch enthält zwei verschiedene Eiweißformen. Das eine hast du bereits
  kennengelernt, es handelt sich um das Whey Protein (Molkeeiweiß). Die andere
  Eiweißform ist das{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_casein_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_casein_text}
  </AffiliateLink>
  , umgangssprachlich auch als Milcheiweiß bezeichnet. Namensgebend für dieses Eiweiß
  ist der Käse und stellt damit eine natürliche Kaseinquelle dar.
    </p>
    <p>
  Casein hat ebenso wie Whey Protein herausragende Eigenschaften, die sich aber
  in wichtigen Aspekten unterscheiden. Im Gegensatz zu Whey ist Casein ein
  langsam verdauliches bzw. langsam resorbierbares Eiweiß. "Langsam" ist aber
  kein Nachteil, sondern eine wichtige Eigenschaft die du dir zu Nutze machen
  solltest.
    </p>
    <p>
  Kasein wird nur langsam verdaut, es liefert geringere Mengen an Aminosäuren
  als Whey Protein, dafür aber über einen deutlich längeren Zeitraum. Im
  Umkehrschluss bist du also lange mit wichtigen Aminosäuren versorgt und kannst
  dadurch lange Phasen überbrücken, in denen du keine Nahrung zu dir nimmst.
    </p>
    <p>
  Geringere Konzentrationen von Aminosäuren regen die Proteinsynthese nicht so
  stark an wie höhere, dafür aber verhindern sie einen Muskelabbau. Insbesondere
  beim lang wirksamen Casein, kannst du dadurch katabole, also muskelabbauende,
  Prozesse verhindern. Die Fachliteratur spricht hierbei zu Recht von einer
  antikabolen, also Muskulatur schützenden, Eigenschaft.
    </p>
    <p>
  Du solltest mit diesen Informationen für die nächsten Abschnitte gewappnet
  sein. Möchtest du dich noch intensiver mit dem Thema Casein beschäftigen, so
  haben wir einen umfangreichen{" "}
  <NavLinkGlobal to="/de/blog/fuer-was-ist-casein-gut" mdxType="NavLinkGlobal">Artikel</NavLinkGlobal>{" "}
  dazu vorbereitet.
    </p>
    <h2>{`Wann nimmt man Whey und wann Casein?`}</h2>
    <p>
  Was du bestimmt schon mal erlebt hast sind zwei Lager, mit gegensätzlichen
  Überzeugungen. Beide sind engstirning und jeweils von ihrer Sichtweise
  überzeugt. Mit fast schon missionarischem Eifer versuchen sie andere, und
  damit dich eingeschlossen, von ihrer Sicht der Dinge zu überzeugen. Als
  langjähriger Ernährungs- und Trainingsexperte habe ich zahlreiche dieser
  Diskussionen miterlebt. Ein sehr beliebtes Streitthema ist hierbei{" "}
  <b>Casein</b> gegen <b>Whey Protein</b>. Welches der beiden Eiweiße ist
  besser, welches dem anderen überlegen. Du wirst verstehen, wieso dieser
  Vergleich unsinnig ist und weshalb beide Eiweißformen ihre Daseinsberechtigung
  haben. Wie in vielen Lebensbereichen geht es um's richtige <b>Timing</b> und
  darum soll es jetzt auch hier gehen!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} imageDesc={props.data.mdx.frontmatter.image_second_desc} imageAuthor={props.data.mdx.frontmatter.image_second_credit_text} imageLink={props.data.mdx.frontmatter.image_second_credit_link} mdxType="Image" />
    <h3>{`Welches Protein in der Früh?`}</h3>
    <p>
  Nachdem du 8 Stunden erholsamen Schlaf bekommen hast, befindet sich dein
  Körper in einem katabolen Zustand. Der Schlaf beträgt etwa ein Drittel des
  gesamten Tages und stellt für den Körper eine Zeit des Fastens dar. Der
  gesamte Mageninhalt konnte verstoffwechselt werden. Die Konzentration der
  Aminosäuren im Blutkreislauf ist jetzt auf einem Minimum. Dein Organismus
  fängt also an die eigenen Muskelzellen zu verstoffwechseln, um Energie
  freizusetzen. Diesen Zustand willst du so schnell wie möglich unterbinden. Der
  perfekte Zeitpunkt also für einen schnell wirksamen{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_whey_text}
  </AffiliateLink>{" "}
  Shake.
    </p>
    <p>
  Nach dem Verzehr dauert es gerade einmal 40 Minuten bis Aminosäuren im Blut
  nachweisbar sind. Die Proteinsynthese befindet sich dadurch auf einem Maximum,
  während die Proteinabbaurate minimiert wurde und dadurch katabole Prozesse
  gestoppt wurden.{" "}
  <b>
    Du siehst also, dass{" "}
    <AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">
      {props.pageContext.frontmatter.product_whey_text}
    </AffiliateLink>{" "}
    oder{" "}
    <AffiliateLink url={props.pageContext.frontmatter.product_whey_iso_url} mdxType="AffiliateLink">
      {props.pageContext.frontmatter.product_whey_iso_text}
    </AffiliateLink>{" "}
    am Besten direkt nach dem Aufstehen genommen werden sollte
  </b>
  .
    </p>
    <p>
  Als Portion empfehle ich dir <b>30 bis 40 g</b> Whey Protein mit{" "}
  <b>200 bis 250 ml</b> Wasser, fettarmer Milch oder Hafermilch einzunehmen.
    </p>
    <h3>{`Was esse ich am besten nach dem Sport?`}</h3>
    <p>
  Die Situation hat Ähnlichkeiten zu der nach dem Aufstehen. Durch den Sport
  werden die Glykogenspeicher deiner Muskulatur stark strapaziert. Auch kommt es
  bei starker Beanspruchung der Muskulatur zu sogenannten Mikrotraumata;
  Mikrotraumate sind kleinste Risse im Muskelgewebe. Durch den Verbrauch deiner
  Energiereserven geht der Körper in einen katabolen Zustand über, ist
  gleichzeitig aber wie ein Schwamm aufnahmebereit für Nährstoffe. Vor allem
  aber benötigt er jetzt schnell verfügbare Aminosäuren, um den
  Regenerationsprozess einzuleiten. Bestimmt kannst du dir jetzt schon denken,
  dass auch hier der perfekte Einnahmezeitpunkt für{" "}
  <b>eine Portion Whey Protein</b> ist! Wenn du dies noch mit komplexen
  Kohlenhydraten optimieren möchtest, so empfiehlt sich der Einsatz von gut
  löslichen{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_instant_oats_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_instant_oats_text}
  </AffiliateLink>
  . Eine gezielte kohlenhydratbedingte Insulinausschüttung nach dem Training führt
  dazu, dass die Aminosäuren aus dem Whey Protein noch schneller und effektiver von
  deiner Muskulatur aufgenommen werden.
    </p>
    <p>
  Als Portion empfehle ich dir <b>30 bis 40 g</b> Whey Protein mit{" "}
  <b>200 bis 250 ml</b> Wasser, fettarmer Milch oder Hafermilch einzunehmen. Bei
  den Instant Oats sollten es, je nach Kalorienbedarf, <b>50 bis 100 g</b>{" "}
  Pulver und zusätzlichen <b>200 - 300 ml</b> Flüssigkeit deiner Wahl sein.
  Achte hierbei darauf, dass du kräftig und lang genug schüttelst. Eventuell
  musst du die Flüssigkeitsmenge erhöhen, damit sich alles gut löst.
    </p>
    <h3>{`Welches Protein für die Nacht?`}</h3>
    <p>
  Du hast gelernt, in welchen Situationen es wichtig ist schnell resorbierbares
  Eiweiß zu dir zu nehmen, um die Proteinsynthese anzukurbeln. Jetzt fragst du
  dich, ob das Gleiche auch für die Mahlzeit vor dem Schlafengehen gilt. Das ist
  eine gute Frage, die jetzt beanwortet wird.
    </p>
    <p>
  Whey Protein ist ein schnell wirksames Eiweiß. Es erzeugt schnell hohe
  Aminosäurewerte im Blut, diese fallen jedoch kurze Zeit nach dem Erreichen des
  Peaks auf ihre Ausgangswerte zurück. Casein hingegen, als langsam verdauliches
  Eiweiß, führt nach dem Verzehr erst nach 3 bis 4 Stunden zum Erreichen der
  maximalen Aminosäurenkonzentration im Blut. Gleichzeitig können diese Werte
  über einen deutlich längeren Zeitraum aufrechterhalten werden. Du wirst also
  lange Zeit mit einer moderaten Menge an Aminosäuren versorgt, die
  Proteinabbaurate wird minimiert. Diese antikatabole Eigenschaft solltest du
  dir zu Nutze machen, indem du{" "}
  <b>
    vor dem Schlafengehen einen Shake mit{" "}
    <AffiliateLink url={props.pageContext.frontmatter.product_casein_url} mdxType="AffiliateLink">
      {props.pageContext.frontmatter.product_casein_text}
    </AffiliateLink>{" "}
  </b>
  zu dir nimmst, um deine Muskulatur zu schützen.
    </p>
    <p>
  Die Portionsempfehlung beläuft sich auch hier auf <b>30 bis 40 g</b> Casein
  mit <b>200 bis 250 ml</b> Wasser, fettarmer Milch oder Hafermilch.
    </p>
    <h2>{`Du solltest Casein und Whey getrennt einnehmen`}</h2>
    <p>
  Jetzt hast du dir bestimmt gedacht, dass du Whey Protein und Casein gemischt
  einnimmst und damit das Beste aus beiden Welten vereinst. Das ist intuitiv und
  eine gute Idee, leider macht uns da unser Verdauungstrakt einen Strich durch
  die Rechnung.
    </p>
    <p>
  Die Gerinnungsprozesse des Caseins im Magen führen dazu, dass die Verdauung
  anderer Nahrungsmittel ebenso verzögert wird. Konkret führt das dazu, dass
  auch das Whey Protein im Magen seine besonderen Eigenschaften verliert und
  ebenso langsam wie das Casein verdaut wird. Das Casein stellt also bei dieser
  Mischung den sprichwörtlichen Flaschenhals dar.{" "}
  <b>
    Du profitierst von den besonderen Eigenschaften des Caseins und des Whey
    Proteins nur, wenn du beide Proteinsorten strategisch geschickt einnimmst.
  </b>{" "}
  Folge hierzu am Besten den oben beschrieben Strategien, um auf der sicheren
  Seite zu sein.
    </p>
    <h2>{`Was hast du in diesem Artikel gelernt?`}</h2>
    <p>
  In diesem Artikel hast du alles Wissenswerte über die perfekte Einnahmezeit
  von Whey Protein und Casein gelernt. Jetzt hast du ein fundiertes Wissen und
  kannst nun gut überlegte Kaufentscheidungen treffen. Die folgenden Punkte
  fassen nochmal für dich zusammen, was du dir merken solltest!
    </p>
    <ul>
      <li parentName="ul">
        <b>Nach dem Aufstehen</b> und <b>nach dem Training</b> ist schnell wirksames Whey
Protein das Eiweiß der Wahl.
      </li>
      <li parentName="ul">
        <b>Vor dem Schlafengehen</b> solltest langsam verdauliches Casein zu dir nehmen.
      </li>
      <li parentName="ul">
        <b>Für längere Phasen ohne Nahrungszufuhr</b> empfiehlt sich ebenfalls Casein.
      </li>
      <li parentName="ul">{`Whey und Casein sollten nicht zusammen eingenommen werden.`}</li>
      <li parentName="ul">{`Casein verlangsamt die Resorptionszeit des Whey Proteins.`}</li>
    </ul>
    <Disclosure mdxType="Disclosure" />
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      